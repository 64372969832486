<template>
  <div class="user-contact">
    <div class="user-contact-initials">{{ initials }}</div>
    <div class="user-contact-name">{{ contact.first_name }} {{ contact.last_name }}</div>
    <div class="user-contact-email description-text">{{ contact.email }}</div>
    <div class="user-contact-phone">{{ modifiedPhoneNumber(contact.phone_number) }}</div>
    <div class="user-contact-actions">
      <!-- <div class="action">
             <edit-button @onClick="$emit('editClick', contact.id)"/>
        </div> -->
      <div class="action delete-btn">
        <div class="delete-address" @click="$emit('deleteClick', contact.id)" v-if="currentCustomerId === contact.customer_id">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992"
              stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Delete
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'

export default {
  mixins: [modifyPhoneNumber],

  props: {
    contact: {
      require: true
    },
    currentCustomerId: {
      require: true
    }
  },

  computed: {
    initials() {
      return this.contact.first_name[0].toUpperCase() + this.contact.last_name[0].toUpperCase()
    }
  }
}
</script>

<style scoped lang="sass">
.user-contact
  background: #FFFFFF
  border-radius: 1rem
  ox-shadow: 0px .5rem 2.5rem -.25rem rgba(31, 31, 24, 0.03)
  padding-top: 1.5rem
  display: flex
  align-items: center
  font-size: .875rem
  margin-bottom: 1rem
  justify-content: center
  width: 15.75rem
  min-width: 252px
  max-width: 252px
  flex-direction: column
  flex: 0 0 15%
  &-initials
    width: 5rem
    height: 5rem
    background: #F5F2D0
    border-radius: 100%
    font-size: 1.5rem
    color: #CFA531
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 1.25rem
  &-name
    font-size: 1.125rem
    margin-bottom: .5rem
    text-align: center
  &-email 
    margin-bottom: 1rem
    text-align: center
    max-width: 90%
    white-space: wrap
    overflow-wrap: anywhere
  &-phone
    margin-bottom: 1.5rem
  &-actions
    display: flex
    align-items: center
    width: 100%
    height: 3rem
    border-top: 1px solid #EBEBE4
    justify-content: center
    .action
      flex: 0 0 50%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    .delete-btn
      display: flex
      justify-content: center
      align-items: center
      //border-left: 1px solid #EBEBE4
      margin-right: .625rem
      .delete-address
        display: flex
        aling-items: center
        gap: .5rem
        cursor: pointer
        
</style>