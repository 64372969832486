<template>
  <div class="request-card">
    <div class="left-part">
      <router-link :to="'/user/requests/' + request.id">
        <div class="title">{{ request.name }}</div>
      </router-link>
      <div class="request-number">Request #{{ request.id }}</div>
      <div class="request-number">User: {{ request.customer_first_name }} {{ request.customer_last_name }}</div>
      <div class="request-info">
        <div class="request-info-details">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 6.66671H2M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.257 13.5903 13.951 13.782 13.5747C14 13.1469 14 12.5868 14 11.4667V5.86671C14 4.7466 14 4.18655 13.782 3.75873C13.5903 3.3824 13.2843 3.07644 12.908 2.88469C12.4802 2.66671 11.9201 2.66671 10.8 2.66671H5.2C4.0799 2.66671 3.51984 2.66671 3.09202 2.88469C2.71569 3.07644 2.40973 3.3824 2.21799 3.75873C2 4.18655 2 4.7466 2 5.86671V11.4667C2 12.5868 2 13.1469 2.21799 13.5747C2.40973 13.951 2.71569 14.257 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z"
              stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ $formatDate(request.delivery_date) }}
        </div>
        <div class="request-info-details">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.3327 14C13.3327 13.0696 13.3327 12.6044 13.2179 12.2259C12.9593 11.3736 12.2924 10.7067 11.4401 10.4482C11.0616 10.3333 10.5964 10.3333 9.66601 10.3333H6.33269C5.40231 10.3333 4.93712 10.3333 4.55859 10.4482C3.70632 10.7067 3.03938 11.3736 2.78084 12.2259C2.66602 12.6044 2.66602 13.0696 2.66602 14M10.9993 5C10.9993 6.65685 9.6562 8 7.99935 8C6.34249 8 4.99935 6.65685 4.99935 5C4.99935 3.34315 6.34249 2 7.99935 2C9.6562 2 10.9993 3.34315 10.9993 5Z"
              stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ request.people }}
        </div>
        <div class="request-info-details">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.00065 7.33329V9.99996M12.0007 5.99996V8.66662M11.334 2.66663C12.9665 2.66663 13.8494 2.91647 14.2887 3.11025C14.3472 3.13606 14.3765 3.14897 14.4609 3.22954C14.5115 3.27784 14.6039 3.41955 14.6277 3.48535C14.6673 3.59512 14.6673 3.65512 14.6673 3.77512V10.9407C14.6673 11.5466 14.6673 11.8495 14.5765 12.0052C14.4841 12.1636 14.3949 12.2372 14.2219 12.2981C14.0519 12.3579 13.7086 12.292 13.0221 12.1601C12.5416 12.0677 11.9717 12 11.334 12C9.33398 12 7.33398 13.3333 4.66732 13.3333C3.03485 13.3333 2.1519 13.0835 1.71257 12.8897C1.65407 12.8639 1.62481 12.851 1.54038 12.7704C1.48978 12.7221 1.3974 12.5804 1.37363 12.5146C1.33398 12.4048 1.33398 12.3448 1.33398 12.2248L1.33398 5.0592C1.33398 4.45335 1.33398 4.15043 1.42484 3.99472C1.51725 3.83634 1.60638 3.7627 1.77936 3.70183C1.94941 3.64199 2.29267 3.70795 2.9792 3.83986C3.45973 3.93219 4.02964 3.99996 4.66732 3.99996C6.66732 3.99996 8.66732 2.66663 11.334 2.66663ZM9.66732 7.99996C9.66732 8.92043 8.92113 9.66662 8.00065 9.66662C7.08018 9.66662 6.33398 8.92043 6.33398 7.99996C6.33398 7.07948 7.08018 6.33329 8.00065 6.33329C8.92113 6.33329 9.66732 7.07948 9.66732 7.99996Z"
              stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ $formatCents(request.budget_cents) }}/person
        </div>
      </div>
    </div>

    <div class="right-part">
      <status-tag :status="request.status"></status-tag>
      <div v-if="currentCustomerId === request.customer_id && !closed" class="actions-list">
        <edit-button @onClick="$emit('editClick', request.id)" text="Edit Request" />
        <div class="cencel-button" @click="$emit('cancelClick')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12M4 4L12 12" stroke="#6E6E68" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          Cancel
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusTag from '../../common/UserStatusTag.vue'
import EditButton from './EditButton.vue'

export default {
  props: {
    request: {
      required: true,
      type: Object,
    },
    closed: {
      type: Boolean,
      default: false
    },
    currentCustomerId: {
      type: Number,
      required: true
    }
  },

  components: {
    StatusTag,
    EditButton,
  },
}
</script>

<style scoped lang="sass">
.request-card
  margin: 1rem 0
  background: #FFFFFF
  box-shadow: 0px 8px 40px -4px rgba(31, 31, 24, 0.03)
  border-radius: 1rem
  min-height: 8.25rem
  padding: 1.5rem 3rem 1.5rem 1.5rem
  display: flex
  justify-content: space-between
  .right-part, .actions-list
    display: flex
    gap: 1.75rem
    align-items: center
  .title
    font-weight: 500
    font-size: 1.25rem
    margin-bottom: .5rem
  .request-number
    color: #70706B
    font-size: .875rem
    margin-bottom: .5rem
    &:last-child
      margin-bottom: 1.125rem
  .request-info
    display: flex
    flex-derection: row
    gap: .75rem
    font-size: .875rem
    &-details
       display: flex
       flex-derection: row
       align-items: center
       gap: .375rem
  .cencel-button
    display: flex
    flex-direction: row
    align-items: center
    color: #6E6E68
    font-size: .875rem
    cursor: pointer
    gap: .375rem
    height: 1.25rem

  a
    color: #1F1F18   
</style>