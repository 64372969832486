<template>
  <div ref="table">
    <div v-if="estimate != null">
      <el-row :gutter="$rowGutter">
        <el-col :span="12">
          <div class="products-from estimate-title no-margin-bottom">
            <div class="tab-number request-title-text">{{ index + 1 }}</div>
            {{ estimate.vendor_name }}
            <el-tag size="small" color="#E3DC82" v-if="availablePoints">
              Rewards Eligible
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.99996 3.50002V12.8334M6.99996 3.50002H4.93746C4.63357 3.50002 4.34212 3.3771 4.12723 3.15831C3.91235 2.93952 3.79163 2.64277 3.79163 2.33335C3.79163 2.02393 3.91235 1.72719 4.12723 1.5084C4.34212 1.2896 4.63357 1.16669 4.93746 1.16669C6.54163 1.16669 6.99996 3.50002 6.99996 3.50002ZM6.99996 3.50002H9.06246C9.36635 3.50002 9.6578 3.3771 9.87269 3.15831C10.0876 2.93952 10.2083 2.64277 10.2083 2.33335C10.2083 2.02393 10.0876 1.72719 9.87269 1.5084C9.6578 1.2896 9.36635 1.16669 9.06246 1.16669C7.45829 1.16669 6.99996 3.50002 6.99996 3.50002ZM11.6666 6.41669V10.9667C11.6666 11.6201 11.6666 11.9468 11.5395 12.1963C11.4276 12.4159 11.2491 12.5943 11.0296 12.7062C10.78 12.8334 10.4534 12.8334 9.79996 12.8334L4.19996 12.8334C3.54656 12.8334 3.21987 12.8334 2.9703 12.7062C2.75078 12.5943 2.5723 12.4159 2.46045 12.1963C2.33329 11.9468 2.33329 11.6201 2.33329 10.9667V6.41669M1.16663 4.43335L1.16663 5.48335C1.16663 5.81005 1.16663 5.9734 1.23021 6.09818C1.28613 6.20794 1.37537 6.29718 1.48513 6.35311C1.60991 6.41669 1.77326 6.41669 2.09996 6.41669L11.9 6.41669C12.2267 6.41669 12.39 6.41669 12.5148 6.35311C12.6245 6.29718 12.7138 6.20794 12.7697 6.09818C12.8333 5.9734 12.8333 5.81005 12.8333 5.48335V4.43335C12.8333 4.10666 12.8333 3.94331 12.7697 3.81853C12.7138 3.70876 12.6245 3.61953 12.5148 3.5636C12.39 3.50002 12.2267 3.50002 11.9 3.50002L2.09996 3.50002C1.77326 3.50002 1.60991 3.50002 1.48513 3.5636C1.37537 3.61953 1.28613 3.70876 1.23021 3.81853C1.16663 3.94331 1.16663 4.10666 1.16663 4.43335Z"
                  stroke="#1F1F18" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </el-tag>
          </div>

          <div class="price-info">
            {{ $formatCents(pricePerPerson) }}/price per person -
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.3334 14C13.3334 13.0696 13.3334 12.6044 13.2186 12.2259C12.9601 11.3736 12.2931 10.7067 11.4408 10.4482C11.0623 10.3333 10.5971 10.3333 9.66675 10.3333H6.33342C5.40304 10.3333 4.93785 10.3333 4.55932 10.4482C3.70705 10.7067 3.04011 11.3736 2.78157 12.2259C2.66675 12.6044 2.66675 13.0696 2.66675 14M11.0001 5C11.0001 6.65685 9.65694 8 8.00008 8C6.34323 8 5.00008 6.65685 5.00008 5C5.00008 3.34315 6.34323 2 8.00008 2C9.65694 2 11.0001 3.34315 11.0001 5Z"
                stroke="#123F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {{ people }} people
          </div>

          <div class="tax-info gray">
            Excludes Tax & Delivery
          </div>
        </el-col>

        <el-col class="print-version" :span="12">
          <el-button v-if="remoteId" type="text" @click="$openInBlank(`/print/estimate/${remoteId}`)">
            <div class="print-btn">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.6665 5.2C2.6665 4.0799 2.6665 3.51984 2.88449 3.09202C3.07624 2.71569 3.3822 2.40973 3.75852 2.21799C4.18635 2 4.7464 2 5.8665 2H10.1332C11.2533 2 11.8133 2 12.2412 2.21799C12.6175 2.40973 12.9234 2.71569 13.1152 3.09202C13.3332 3.51984 13.3332 4.0799 13.3332 5.2V14L11.4998 12.6667L9.83317 14L7.99984 12.6667L6.1665 14L4.49984 12.6667L2.6665 14V5.2Z"
                  stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Printable version
            </div>
          </el-button>

          <br />

          <el-button type="text" @click="getItemsReports" :loading="submitting">
            <div>Download CSV</div>
          </el-button>
        </el-col>
      </el-row>

      <div class="user-custome-estimates-table">
        <table class="user-custome-table">
          <thead>
            <tr>
              <th class="left-text">Item</th>
              <th>Qty</th>
              <th>Total Serves</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr class="user-custome-table-row" v-for="(item, i) in itemsWithoutTax" :key="i">
              <td class="user-custome-table-item left-text">
                <div class="pos-name">{{ item.name }}</div>
                <div class="secondary" v-if="item.type == 'PRODUCT'">
                  {{ item.description }}
                </div>
                <div class="secondary serves-count" v-if="item.serves">
                  Serves: {{ item.serves }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ item.serves * item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ $formatCents(item.sale_price_cents) }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ $formatCents(item.sale_price_cents * item.quantity) }}
                </div>
              </td>
            </tr>
            <tr class="user-custome-table-row sub-info">
              <td class="user-custome-table-item left-text">
                <div class="sub-total secondary-text" :class="availablePoints ? 'with-points' : ''">Subtotal</div>
                <div class="secondary-text points-row" v-if="availablePoints">
                  <b style="color: #125F43;">Use&nbsp;Points&nbsp;({{ availablePoints }})</b>
                  <el-input style="min-width: 100px; max-width: 100px; font-size: 15px;" v-model="usedPoints"
                    @change="setUsedPoints"></el-input>
                </div>
                <div class="sub-total secondary-text">Tax</div>
                <div class="secondary-text tip-row" v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')">
                  Driver tip
                  <InfoBtn width="220px"
                    :alertInfo="`The tip goes directly to the driver. \n The tip does not go to SimpleCater`" />
                </div>
              </td>

              <td colspan="3">
                <div class="sub-total">&nbsp;</div>
                <div class="sub-total" v-if="availablePoints">&nbsp;</div>
                <div class="sub-total">&nbsp;</div>
                <tip-block v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')" @tips-add="addTips($event)"
                  :total="tipsTotalPrice" :initialValue="estimate.tips" />
              </td>

              <td class="right-text">
                <div class="sub-total sub-total-price">
                  {{ $formatCents(subtotalCents) }}
                </div>
                <div class="sub-total sub-total-price" v-if="availablePoints">
                  <span class="points-in-dollars">-{{ $formatCents(pointsInDollars) }}</span>
                </div>
                <div class="sub-total sub-total-price">
                  {{ $formatCents(tax.sale_price_cents) }}
                </div>
                <div v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')">
                  {{ $formatCents(tips) }}
                </div>
              </td>
            </tr>
            <tr class="tip-row-container">
              <td class="user-custome-table-item left-text "></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="user-custome-table-row">
              <td class="user-custome-table-item left-text non-border">
                <div>Total</div>
              </td>
              <td class="non-border"></td>
              <td class="non-border"></td>
              <td class="non-border"></td>
              <td class="non-border right-text">
                <div>{{ $formatCents(totalCents + tips - pointsInDollars) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <br />

      <div class="estimate-footer" v-if="estimate.status != 'REJECTED' && this.user.id === this.customerId">
        <div class="reject-btn" @click="onReject">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_904_34377)">
              <path
                d="M10.0002 6.00001L6.00016 10M6.00016 6.00001L10.0002 10M14.6668 8.00001C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00001C1.3335 4.31811 4.31826 1.33334 8.00016 1.33334C11.6821 1.33334 14.6668 4.31811 14.6668 8.00001Z"
                stroke="#D92D20" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_904_34377">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Reject This Estimate
        </div>
        <div class="estimate-req-sub">
          <user-default-button :textColor="'#1F1F18'" :borderColor="'#F4F4EF'" btnColor="white" :withBorder="true"
            @onClick="onRequestChanges">
            Request changes
          </user-default-button>
          <user-default-button @onClick="onAccept" :borderColor="'#125F43'" btnColor="#125F43">
            <div class="submit-btn">
              Submit
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.6665 8H13.3332M13.3332 8L9.33317 4M13.3332 8L9.33317 12" stroke="white"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </user-default-button>
        </div>
      </div>
      <div v-if="estimate.status === 'REJECTED' && this.user.id === this.customerId" class="estimate-rejected">
        Estimate was rejected
      </div>

      <accept-estimate-dialog :estimate="estimate" :contact="contact" :request="this.request" :address="this.address"
        :visible.sync="displayAcceptDialog">
      </accept-estimate-dialog>
      
      <pop-up :showPopUp="showChangesPopUp" :title="'Request changes'" @closePopUp="showChangesPopUp = false">
        <div class="change-pop-text">
          What would you like to be changed?
        </div>
        <el-input v-model="changeText"></el-input>
        <div class="change-pop-up-footer">
          <base-button @onClick="showChangesPopUp = false">Cancel</base-button>
          <base-button :confirmBtn="true" @onClick="changeRequest()">Request changes</base-button>
        </div>
      </pop-up>

      <pop-up :showPopUp="showRejectPopUp" :title="'Reject'" @closePopUp="showRejectPopUp = false">
        <div class="change-pop-text">
          What didn’t you like about this menu?
        </div>
        <el-input v-model="rejectText"></el-input>
        <div class="change-pop-up-footer">
          <base-button @onClick="showRejectPopUp = false">Cancel</base-button>
          <base-button :confirmBtn="true" @onClick="rejectRequest()">Reject submit</base-button>
        </div>
      </pop-up>
    </div>
  </div>
</template>

<script>
import PopUp from "@/common/PopUp.vue";
import EstimateViewer from "./EstimateViewer.vue";
import UserDefaultButton from "./UserDefaultButton.vue";
import BaseButton from "./BaseButton.vue";
import TipBlock from "./TipBlock.vue";
import InfoBtn from "./InfoBtn.vue";

export default {
  name: "UserEstimateViewer",

  components: { UserDefaultButton, PopUp, BaseButton, TipBlock, InfoBtn },

  mixins: [EstimateViewer],

  data() {
    return {
      showChangesPopUp: false,
      showRejectPopUp: false,
      changeText: "",
      rejectText: "",
      tips: 0,
      usedPoints: 0,
      availablePoints: 0,
      allPoints: 0,
      pointsInDollars: 0,
      exchangeRate: 0,
      user: null,
      user_permissions: {
        worldwide_view: false,
        permissions: {}
      },
    };
  },

  props: {
    contact: {
      require: false
    },
    customerId: {
      require: false,
      type: Number
    }
  },

  methods: {
    onRequestChanges() {
      this.showChangesPopUp = true;
    },

    onReject() {
      this.showRejectPopUp = true;
    },

    changeRequest() {
      const target = `/estimates/${this.estimate.id}/request_changes`;
      this.$simplecater
        .post(target, { customer_notes: this.changeText })
        .then(() => {
          this.$message("The have changes have been requested");
          this.showChangesPopUp = false;
          this.$router.push("/user/requests");
        })
        .catch(() => {
          this.$message.error("The request for changes failed");
        });
    },

    rejectRequest() {
      const target = `/estimates/${this.estimate.id}/reject`;
      this.$simplecater
        .post(target, { customer_notes: this.rejectText })
        .then((r) => {
          this.estimate = r.data.data;
          this.showRejectPopUp = false;
          this.$message("The estimate has been rejected");
        })
        .catch(() => {
          this.$message.error("Rejection failed");
        });
    },

    getItemsReports() {
      this.submitting = true;
      this.$simplecater
        .get(`/estimates/${this.estimate.id}/csv_report`)
        .then((r) => {
          this.submitting = false;

          if (r.status === 204) {
            this.$message.warning("No data for this estimate");
          } else {
            this.downloadCsv(r.data);
          }
        })
        .catch(() => {
          this.$message.error("Failed loading data");
        });
    },

    downloadCsv(data) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      hiddenElement.target = "_blank";
      hiddenElement.download = `items-from-request-${this.request.id}-vendor-${this.estimate.vendor_name}.csv`;
      hiddenElement.click();
    },

    addTips(tips) {
      this.tips = tips
      this.request.tips = this.tips.toFixed(2)
    },

    getPoints() {
      if (this.user && this.userCanUsePoints()) {
        this.$simplecater.get(`points/`).then((r) => {
          this.allPoints = r.data.data.amount;
        }).catch(() => {
          this.$message.error("Failed to load the customer's points");
        })
      }
    },

    getSettings() {
      this.$simplecater.get(`/settings`).then((r) => {
        let settings = r.data.data.find((setting) => setting.key === 'POINTS_EXCHANGE_RATE');

        if (settings) {
          this.exchangeRate = settings.value;
        }
      }).catch(() => {
        this.$message.error("Failed to load the settings");
      })
    },

    getUser() {
      return this.$simplecater.get('/account').then((r) => {
        this.user = r.data.data
      }).catch(() => {
        this.$message.error("Failed to load the account information")
      })
    },

    async getPermissionsList() {
      const target = `/accounts/${this.user.account_id}/customers/permissions-list`

      await this.$simplecater.get(target).then((r) => {
        const permissions = r.data.data.reduce((acc, permission) => {
          if (permission === 'worldwide_view') {
            this.user_permissions.worldwide_view = false;
          } else {
            acc[permission] = false;
          }
          return acc;
        }, {});

        this.user_permissions = {
          worldwide_view: false,
          permissions
        };
      }).catch(() => {
        this.$message.error("Failed to load the user permissions")
      })
    },

    async getUserPermissions() {
      const target = `/accounts/${this.user.account_id}/customers/${this.user.id}/permissions`;
      await this.$simplecater.get(target).then((r) => {
        const userPerms = r.data.data;
        if (userPerms.worldwide_view !== undefined) {
          this.user_permissions.worldwide_view = userPerms.worldwide_view;
        }
        if (userPerms.permissions) {
          Object.keys(userPerms.permissions).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(this.user_permissions.permissions, key)) {
              this.user_permissions.permissions[key] = userPerms.permissions[key];
            }
          });
        }
      }).catch(() => {
        this.$message.error("Failed to load the user permissions");
      });
    },

    userCanUsePoints() {
      return this.user_permissions.permissions.access_points
    },

    setAllPoints() {
      this.usedPoints = this.availablePoints;
      this.culculatePointsInCents();

      this.estimate.usedPoints = this.usedPoints;
      this.estimate.pointsPrice = this.exchangeRate / 100; // price of 1 point in cetns
    },

    setUsedPoints() {
      this.sanitizeUsedPoints();

      if (this.usedPoints > this.availablePoints) {
        this.setAllPoints();
      } else {
        this.culculatePointsInCents();
        this.estimate.usedPoints = this.usedPoints;
        this.estimate.pointsPrice = this.exchangeRate / 100;
      }
    },

    sanitizeUsedPoints() {
      this.usedPoints = this.usedPoints.replace(/\D/g, '');
      this.usedPoints = this.usedPoints.length > 0 ? parseInt(this.usedPoints) : 0;
    },

    culculatePointsInCents() {
      this.pointsInDollars = this.usedPoints / this.exchangeRate * 100;
    },

    setAvaliablePoints() {
      if (this.estimate == null || this.allPoints == null) return;
      if (this.estimate.vendor_rewards == false) return;

      this.availablePoints = Math.min(this.allPoints, Math.round((this.subtotalCentsProductsOnly / 100) * this.exchangeRate));
    }
  },

  watch: {
    estimate: function () {
      this.setAvaliablePoints();
    },

    allPoints: function () {
      this.setAvaliablePoints();
    }
  },

  created() {
    this.getUser().then(() => {
      this.getPermissionsList().then(() => {
        this.getUserPermissions().then(() => {
          this.getPoints();
        });
      });
    });
    this.getSettings();
  },
};
</script>

<style lang="sass">
.request-title-text
  margin-left: 18px
.estimate-rejected
  color: #D92D20
  text-align: center
  margin-top: 2rem
  font-size: 1.125rem
.change-pop-text
  margin-bottom: 2rem
.change-pop-up-footer
  display: flex
  gap: 2rem
  margin-top: 2rem
.numeric-summary
  width: 100%
  display: flex
  & > div
    flex: 0 0 50%
.status-wrapper
  margin: 0 0 0.5rem
.secondary
  font-size: 12px
  font-style: italic
  white-space: pre-line
  word-break: break-word

.print-version
  text-align: right

.products-from
   display: flex
   border-bottom: none
   gap: .5rem
   font-size: 20px
   color: black
.price-info
   display: flex
   align-items: center
   gap: .375rem
   font-size: .875rem
   margin-left: 2.8rem
   width: 100%
.print-btn
  display: flex
  align-items: center
  gap: .5rem
  color: #125F43
.user-custome-table
  margin-top: 1.5rem
  width: 100%
  text-align: center
  th, td
      padding: 1rem
  &-item
      max-width: 20vw
  thead
      background: #FAFAF5
      height: 2.625rem
      border: none
      font-size: .75rem
      font-weight: 500
      color: #70706B
  .left-text
      text-align: left
  .right-text
      text-align: right
  td
      border-bottom: 1px solid #EBEBE4
  .non-border
      border-bottom: none
  .td-content-with-icon
      display: flex
      align-items: center
      justify-content: center
      gap: .375rem
  .pos-name
      font-size: 1.25rem
      margin-bottom: .375rem
      hyphens: auto
  .sub-total
      margin-bottom: .75rem
  .with-points
      margin-bottom: 3px !important
  .secondary-text
      color: #70706B
      font-size: 14px
  .points-row
    display: flex
    flex-direction: row
    justify-items: center
    align-items: center
    justify-content: flex-start
    .el-input
      margin-left: 21px
      margin-right: 12px
      .el-input__inner
        text-align: center
        font-family: Inter
        font-size: 16px
        font-weight: bold
        line-height: 20px
        letter-spacing: -0.03em
        background-color: #E9F2EC
    .use-all-points
      font-family: Inter
      font-size: 12px
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.03em
      text-align: left
      color: #125F43

  .points-in-dollars
    color: #125F43

.submit-btn
      display: flex
      align-items: center
      gap: .5rem
.reject-btn
      display: flex
      align-items: center
      gap: .5rem
      color: #D92D20
      cursor: pointer
.estimate-footer
      display: flex
      justify-content: space-between
.estimate-req-sub
      display: flex
      gap: 1.25rem
.tax-info
  font-size: 0.8rem
  margin-left: 2.8rem
  margin-top: 6px

.estimate-title
  color: black !important
  border-bottom: none !important
  .el-tag--small
    color: #1f1f18
    font-family: Inter
    font-size: 12px
    font-weight: 500
    line-height: 12px
    letter-spacing: -0.03em
    border-radius: 16px
    display: flex
    align-items: center
    gap: 4px
    padding: 0 10px

.no-margin-bottom
  margin-bottom: 6px !important
  padding-bottom: 0 !important

.tip-row
  display: flex
  align-items: center
  gap: 6px

.sub-info
  td
    border-bottom: none
    padding-bottom: 0

.tip-row-container
  td
    padding-top: 0
</style>
