<template lang='pug'>
.main-container

  .view-content
    el-card
      div(slot='header')
        i.mat-icon assignment_turned_in
        | Orders
      el-tabs( v-model='activeIndex', type='card' )
        el-row(:gutter='10')
          el-col(:span='9')
            el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search")
        el-autocomplete(
          v-show="false"
          v-model="currentCustomer"
          :fetch-suggestions="searchCustomer"
          prefix-icon="el-icon-search"
          placeholder="Find customer"
          @select="handleSearch" @blur="handleSearchBlur")
        el-tab-pane(
          v-for='filter in filters' :label='$upcaseFirst(filter)',
          :name='filter', :key='filter'
        )
          order-list(:filter='filter' :sorting='sorting' v-if='filter == activeIndex' :customer='customerId' :active-index='activeIndex')

  router-view
</template>

<script>

  import OrderList from './blocks/OrderList.vue'

  function data(){
    return {
      filters: ['submitted', 'received', 'ordered', 'assigned', 'all'],
      sorting:'',
      orders: null,
      activeIndex: 'submitted',
      loadingOrders: true,
      loadingContacts: true,
      currentCustomer: '',
      customerId: null,
      customers: [],
      timeout:  null
    }
  }

  function searchCustomer(queryString, cb) {
    if (queryString) {
      const request = `/customers?account_name=${queryString}`;
      this.getCustomers(request).then(() => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(this.customers);
        }, 500);
      })
    }
  }

  function handleSearch(item) {
    this.customerId = item.link;
  }

  function handleSearchBlur(e) {
    if (e.target.value === '') {
      this.customerId = '';
    }
  }

  function getCustomers(filterName) {
    return this.$simplecater.get(filterName).then((r)=>{
      this.customers = this.generateSearchArray(r.data.data);
    });
  }

  function generateSearchArray(array) {
    return array.map(item => ({value: item.account_name, link: item.id}))
  }

  function getOrders() {
    this.loadingOrders = true;
    return this.$simplecater.get('/orders').then((r)=> {
      this.orders = r.data.data;
      this.loadingOrders = false;
    })
  }

  function created(){
    this.getOrders();
  }

  const components = { OrderList }

  const methods = {
    getOrders, getCustomers, searchCustomer, handleSearch, generateSearchArray, handleSearchBlur
  }

  export default { methods, components, data, created }

</script>
