<template>
  <div class="user-card" v-if="data.id !== 'OFFLINE'">
    <div class="user-card-content">
      <div v-html="cardInfo.icon"></div>
      <div class="card-name">
        {{ data.holder_name }}
      </div>
      <div class="card-number">
        <div>&#x2022;&#x2022;&#x2022;&#x2022;</div>
        <div>&#x2022;&#x2022;&#x2022;&#x2022;</div>
        <div>&#x2022;&#x2022;&#x2022;&#x2022;</div>
        {{ cardInfo.lastNumbers }}
      </div>
      <div class="expaired-date">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
            stroke="#4C4C46" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Expiry {{ data.expiration }}
      </div>
      <div class="card-owner">
        <i class="mat-icon">person</i>{{ fullName }}
      </div>
    </div>

    <div class="delete-card" @click="$emit('deleteClick', data.id)" v-if="this.currentCustomerId === data.customer_id">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992"
          stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      Delete
    </div>
  </div>
</template>

<script>
//expiration
export default {
  props: {
    data: {
      require: true
    },
    currentCustomerId: {
      require: true
    }
  },

  computed: {
    cardInfo() {
      const type = this.data.name.split('-')[0].toLowerCase()
      const number = this.data.name.split('-')[1]
      switch (type) {
        case 'visa':
          return {
            icon: `<svg width="42" height="13" viewBox="0 0 42 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1992 12.8126H14.7969L16.9249 0.228271H20.327L18.1992 12.8126Z" fill="#00579F"/>
                                    <path d="M30.5341 0.535765C29.863 0.281139 28.7986 0 27.4825 0C24.1226 0 21.7566 1.71352 21.7421 4.16334C21.7142 5.97082 23.4361 6.97473 24.7239 7.5774C26.0402 8.19324 26.4877 8.59519 26.4877 9.14413C26.4743 9.98719 25.424 10.3758 24.4445 10.3758C23.0861 10.3758 22.3583 10.1754 21.2522 9.70641L20.8042 9.50534L20.3281 12.3302C21.1261 12.6779 22.5963 12.9865 24.1226 13C27.6925 13 30.0167 11.313 30.0442 8.70231C30.0578 7.26975 29.1486 6.17206 27.1883 5.27509C25.9984 4.69929 25.2696 4.31103 25.2696 3.72189C25.2836 3.1863 25.886 2.63772 27.2293 2.63772C28.3353 2.61085 29.148 2.86512 29.7636 3.11957L30.0714 3.2532L30.5341 0.535765Z" fill="#00579F"/>
                                    <path d="M35.0549 8.35443C35.3351 7.63147 36.4133 4.83343 36.4133 4.83343C36.3991 4.8603 36.6929 4.09713 36.8609 3.62863L37.0987 4.71297C37.0987 4.71297 37.743 7.72525 37.8829 8.35443C37.3512 8.35443 35.7269 8.35443 35.0549 8.35443ZM39.2546 0.228271H36.623C35.8114 0.228271 35.1948 0.455674 34.8447 1.2724L29.791 12.8124H33.3609C33.3609 12.8124 33.9487 11.2592 34.075 10.9247C34.4666 10.9247 37.9394 10.9247 38.4433 10.9247C38.5409 11.3665 38.8492 12.8124 38.8492 12.8124H41.9994L39.2546 0.228271Z" fill="#00579F"/>
                                    <path d="M11.9566 0.228271L8.62464 8.80959L8.26054 7.0692C7.64453 5.06101 5.71259 2.87916 3.55664 1.79429L6.6086 12.7993H10.2064L15.5543 0.228271H11.9566Z" fill="#00579F"/>
                                    <path d="M5.52996 0.228271H0.0560008L0 0.482542C4.27003 1.52685 7.09799 4.04411 8.25987 7.06973L7.0699 1.2861C6.87398 0.482364 6.27193 0.254784 5.52996 0.228271Z" fill="#F79009"/>
                                </svg>`,
            lastNumbers: number
          }
        case 'mastercard':
          return {
            icon: `<svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0527 2.56616H25.9537V21.4324H14.0527V2.56616Z" fill="#EB5B13"/>
                                    <path d="M15.2778 12.0009C15.2764 10.1842 15.7007 8.39103 16.5186 6.75703C17.3366 5.12303 18.5268 3.69102 19.9991 2.56939C18.1755 1.1784 15.9856 0.313428 13.6795 0.0733272C11.3734 -0.166774 9.04424 0.227685 6.95821 1.21162C4.87217 2.19555 3.11342 3.72927 1.88297 5.63747C0.652515 7.54567 0 9.75138 0 12.0025C0 14.2536 0.652515 16.4593 1.88297 18.3675C3.11342 20.2757 4.87217 21.8094 6.95821 22.7934C9.04424 23.7773 11.3734 24.1718 13.6795 23.9317C15.9856 23.6916 18.1755 22.8266 19.9991 21.4356C18.5263 20.3136 17.3358 18.8811 16.5179 17.2465C15.6999 15.6119 15.2759 13.818 15.2778 12.0008V12.0009Z" fill="#EB001B"/>
                                    <path d="M38.8189 19.4358V19.0493H38.9906V18.9692H38.582V19.0493H38.7434V19.4359L38.8189 19.4358ZM39.6121 19.4358V18.9693H39.4885L39.3443 19.3025L39.2 18.9692H39.0765V19.4358H39.1657V19.0826L39.2997 19.3859H39.3923L39.5263 19.0826V19.4358H39.6121Z" fill="#F79E1B"/>
                                    <path d="M40.001 12.0007C40.0009 14.252 39.3482 16.4579 38.1176 18.3661C36.8869 20.2744 35.1279 21.8081 33.0416 22.7919C30.9553 23.7757 28.6259 24.1699 26.3196 23.9294C24.0134 23.689 21.8234 22.8236 20 21.4321C21.4718 20.3095 22.6616 18.8771 23.4798 17.243C24.2979 15.6089 24.723 13.8159 24.723 11.999C24.723 10.1822 24.2979 8.38912 23.4798 6.75503C22.6616 5.12095 21.4718 3.68855 20 2.56592C21.8234 1.17449 24.0134 0.309084 26.3197 0.0686338C28.6259 -0.171817 30.9553 0.222388 33.0416 1.20619C35.1279 2.18999 36.8869 3.72368 38.1176 5.63196C39.3483 7.54024 40.0009 9.7461 40.001 11.9974V12.0007Z" fill="#F79E1B"/>
                                </svg>`,
            lastNumbers: number
          }
        default:
          return {
            icon: ``,
            lastNumbers: number
          }
      }
    },

    fullName() {
      return `${this.data.customer_first_name} ${this.data.customer_last_name}`
    }
  }
}
</script>

<style scoped lang="sass">
.user-card
    background: #FFFFFF
    border-radius: 1rem
    ox-shadow: 0px .5rem 2.5rem -.25rem rgba(31, 31, 24, 0.03)
    padding: 1.5rem
    display: flex
    gap: 2.5rem
    align-items: center
    font-size: .875rem
    margin-bottom: 1rem
    justify-content: space-between
    &-content
        display: flex
        align-items: center
        gap: 3.75rem
.card-number, .expaired-date, .delete-card, .card-owner
    color: #6E6E68
    display: flex
    align-items: center
    gap: .375rem
.delete-card
    cursor: pointer     
</style>