<template>
  <div class="main-container">
    <div class="view-content">
      <div class="address-header">
        <h2 class="page-title">Addresses</h2>
        <default-button @onClick="activeIndex === 'addresses' ? goToAddressBuilder() : goToContactBuilder()"
          :style="{ marginTop: '1.2rem', fontSize: '.875rem' }">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="white" stroke-width="1.33333"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ activeIndex === 'addresses' ? 'New Address' : 'New Contact ' }}
        </default-button>
      </div>

      <div class="sub-header">
        <div class="sub-header-left-part">
          <toggle-switch :options="toggleOption" :disabled="false" @change="switchTab($event.value)" />
        </div>
      </div>
      <div v-if="activeIndex === 'addresses'" v-loading="contacts == addresses">
        <address-card v-for="address in addresses" :key="address.id" :address="address" :currentCustomerId="customer.id"
          @editClick="goToAddressEditer(address)" @deleteClick="deleteAddress(address.id)" />
      </div>
      <div class="contacts-section" v-if="activeIndex === 'contacts'" v-loading="contacts == null">
        <user-contact-card v-for="contact in contacts" :key="contact.id" :contact="contact" :currentCustomerId="customer.id"
          @deleteClick="deleteContact(contact.id)" />
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Toolbar from '../layout/Toolbar.vue'
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'
import DefaultButton from '@/layout/Blocks/DefaultButton.vue'
import AddressCard from './blocks/AddressCard.vue'
import UserContactCard from './blocks/UserContactCard.vue'

const mixins = [modifyPhoneNumber]

function data() {
  return {
    customer: null,
    contacts: null,
    addresses: null,
    loadingAddresses: true,
    loadingContacts: true,
    activeIndex: 'addresses',
    toggleOption: {
      layout: {
        color: 'black',
        backgroundColor: 'white',
        selectedColor: 'white',
        selectedBackgroundColor: 'green',
        borderColor: 'black',
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: true
      },
      size: {
        fontSize: .875,
        height: 2.125,
        padding: .4375,
        width: 16.25
      },
      config: {
        delay: 0,
        preSelected: 'addresses',
        disabled: false,
        items: [
          { name: 'Addresses', value: 'addresses', color: '#125F43', backgroundColor: '#E9F2EC' },
          { name: 'Contacts', value: 'contacts', color: '#125F43', backgroundColor: '#E9F2EC' }
        ]
      }
    }
  }
}

async function getCustomer() {
  await this.$simplecater.get('/account').then((r) => {
    this.customer = r.data.data
  }).catch(() => {
    this.$message.error("Failed to load the user information")
  })
}

function switchTab(e) {
  this.activeIndex = e
}

function getAddresses() {
  this.loadingAddresses = true
  return this.$simplecater.get('/addresses').then((r) => {
    this.addresses = r.data.data
    this.loadingAddresses = false
  })
}

function getContacts() {
  this.loadingContacts = true
  return this.$simplecater.get('/contacts').then((r) => {
    this.contacts = r.data.data
    this.loadingContacts = false
  })
}

function goToAddressBuilder() {
  this.$router.push("/user/address_book/insert_address/new")
}

function goToAddressEditer({ id }) {
  this.$router.push(`/user/address_book/insert_address/${id}/edit`)
}


function goToContactBuilder() {
  this.$router.push("/user/address_book/new_contact")
}

function deleteAddressDialog(id) {
  const title = "Delete Address"
  const msg = "The address will be deleted. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, delete',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.deleteAddress(id)
  }).catch(() => { })
}

function deleteAddress(id) {
  const target = `/addresses/${id}`
  this.$simplecater.delete(target).then(() => {
    this.$message("The address has been deleted")
    this.getAddresses()
  }).catch(() => {
    this.$message.error("Deleting failed. Address might have been already used elsewhere.")
  })
}

function deleteContactDialog(id) {
  const title = "Delete Contact"
  const msg = "The contact will be deleted. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, delete',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.deleteContact(id)
  }).catch(() => { })
}

function deleteContact(id) {
  const target = `/contacts/${id}`
  this.$simplecater.delete(target).then(() => {
    this.$message("The contact has been deleted")
    this.getContacts()
  }).catch(() => {
    this.$message.error("Deleting failed. Contact might have been already used elsewhere.")
  })
}

function created() {
  this.getAddresses()
  this.getContacts()
  this.getCustomer()
  this.$bus.$on('address-saved', this.getAddresses)
  this.$bus.$on('contact-saved', this.getContacts)
}

function fullAddress({ row: { zip_code, state, city, address_line_1, address_line_2 } }) {
  return `${address_line_1}${address_line_2 && `, ${address_line_2}`}, ${city}, ${state}, ${zip_code}`
}

const watch = {
  '$route'() {
    console.log("Reloading data")
    this.getAddresses()
    this.getContacts()
  }
}

const components = { Toolbar, DefaultButton, AddressCard, UserContactCard }

const methods = {
  getCustomer, getAddresses, getContacts, goToAddressBuilder,
  goToContactBuilder, deleteAddressDialog, deleteAddress,
  deleteContactDialog, deleteContact, goToAddressEditer, fullAddress, switchTab
}

export default { methods, components, data, created, watch, mixins }

</script>

<style lang="sass" scoped>
  .address-header
    display: flex
    justify-content: space-between

  .sub-header
    display: flex
    flex-direction: row
    justify-content: flex-start
    margin-bottom: 1.5rem

  .contacts-section
    display: flex
    flex-wrap: wrap
    gap: 1.5rem

</style>