<template>
  <div>

    <div v-for="order in filtredOreder" :key="order.id">
      <order-card :order="order" @editClick="onEditDialog($event)" :closed="filter === 'closed'"
        @cancelClick="markCancelledDialog(request.id)" />
    </div>

    <load-more-btn v-if="pagination.pages > 1 && currentList <= pagination.pages && filtredOreder.length > 0"
      @loadMoreClick="loadMore" />

    <div class="empty-container" v-if="!filtredOreder || filtredOreder.length === 0">
      <empty-block :imagePath="'Fruit'" title="There are no open orders available" />
    </div>
  </div>
</template>

<script>

import StatusTag from '../../common/StatusTag.vue'
import OrderCard from '../blocks/OrderCard.vue'
import LoadMoreBtn from './LoadMoreBtn.vue'
import EmptyBlock from './EmptyBlock.vue'

function data() {
  return {
    currentList: 1,
    orders: [],
    displayScheduler: false,
    orderOnSchedule: null,
    pagination: {
      per_page: null,
      current: null,
      pages: null,
      total: null,
    }
  }
}

function created() {
  this.getData()
}

function onPaginationChange(current_page) {
  this.getData(current_page);
}

function loadMore() {
  this.currentList += 1
  this.getData(this.currentList, true)
}

function confirmDialog(id) {
  const title = "Confirm order"
  const msg = "The customer will be invoiced and charged. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Confirm & bill order',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.confirm(id)
  }).catch(() => { })
}

function scheduleDialog(order) {
  this.orderOnSchedule = order
  this.displayScheduler = true
}

function confirm(id) {
  const target = `/orders/${id}/confirm`
  this.$simplecater.post(target).then(() => {
    this.$message("The order has been confirmed")
    this.getData()
  }).catch(() => {
    this.$message.error("Confirmation failed")
  })
}

function schedule() {
  this.$message("PENDING WIRING")
}

function goToInvoice(id) {
  this.$router.push(`/invoices/${id}`)
}

function goToDelivery(id) {
  this.$router.push(`/deliveries/${id}`)
}

function getData(current_page, loadMoreAction) {
  let reqParams = (current_page ? { params: { page: current_page } } : { params: {} });
  if (this.sorting) {
    this.$isNumber(this.sorting) ? reqParams.params.id = this.sorting : reqParams.params.q = this.sorting
  }

  this.$simplecater.get(this.targetUri, reqParams).then((r) => {
    this.orders = loadMoreAction ? this.orders.concat(r.data.data) : r.data.data;
    this.pagination = r.data.pagination;
  }).catch(() => {
    this.$message.error('Failed to load data')
  })
}

function markCancelledDialog(id) {
  const title = "Cancel Order"
  const msg = "The order will be cancelled. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, cancel order',
    cancelButtonText: 'Cancel',
    confirmButtonClass: 'order-list__red-btn',
    type: 'error'
  }).then(() => {
    this.markCancelled(id)
  }).catch(() => { })
}

function markCancelled(id) {
  const target = `/orders/${id}/mark_cancelled`
  this.$simplecater.post(target).then(() => {
    this.$message("The order has been cancelled")
    this.getData()
  }).catch(() => {
    this.$message.error("Cancelling failed")
  })
}

const watch = {
  sorting: function () {
    this.getData();
  },
  filter: function (newFilter) {
    console.log("FILTER FROM TO", this.filter, newFilter)
  }
}

const computed = {
  targetUri: function () {
    if (this.filter == 'all') {
      return '/orders'
    } else {
      return `/orders/${this.filter}`
    }
  },
  filtredOreder: function () {
    if (this.orders && this.orders.length > 0) {
      if (this.curentFilterStatus !== 'ALL_STATUSES') {
        const filtredOrders = this.orders.filter(order => {
          return order.status === this.curentFilterStatus
        })
        return filtredOrders
      } else {
        return this.orders
      }
    } else return null
  }
}

const components = {
  StatusTag, OrderCard, EmptyBlock, LoadMoreBtn
}

const methods = {
  getData, onPaginationChange, confirm, confirmDialog, schedule, scheduleDialog,
  goToInvoice, goToDelivery, markCancelledDialog, markCancelled, loadMore
}

const props = {
  filter: { required: true },
  sorting: { required: false },
  curentFilterStatus: { require: false }
}

export default { methods, components, data, created, watch, props, computed }
</script>

<style lang="sass">
  .order-list__red-btn
    background: red !important
    border-color: red !important
    &:hover
      opacity: 0.6
  .empty-container
    margin-top: 1.5rem
    img
      max-width: 20vw  

</style>
