<template>
  <div class="main-container">
    <div class="view-content">
      <div class="biling-header">
        <h2 class="page-title">Billing</h2>
        <default-button @onClick="addNewCard" :style="{ marginTop: '1.2rem', fontSize: '.875rem' }">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="white" stroke-width="1.33333"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Add Сard
        </default-button>
      </div>
      <div class="sub-header">
        <div class="sub-header-left-part">
          <toggle-switch :options="toggleOption" :disabled="false" @change="switchTab($event.value)" />
        </div>
        <div class="search">
          <el-input v-show="activeIndex === 'invoices'" v-model="sorting" prefix-icon="el-icon-search"
            placeholder="Search"></el-input>
        </div>
      </div>
      <div class="main-content">
        <div v-if="activeIndex === 'invoices'" v-loading="invoices == null">
          <user-invoce-card v-for="data in invoices" :key="data.id" :data="data" :currentCustomerId="customer.id"
            @viewInvoceClick="$openInBlank(`/print/invoices/${$event}`)" />
        </div>
        <div v-else-if="activeIndex === 'payment_methods'">
          <user-card-view v-for="data in cards" :key="data.id" :data="data" :currentCustomerId="customer.id" @deleteClick="deleteCard(data)" />
        </div>
      </div>
      <el-dialog title="Add New Card" :visible.sync="showAddCardDialog" width="650px" :close-on-click-modal="false">
        <add-credit-card @card-added="handleNewCard" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DefaultButton from '@/layout/Blocks/DefaultButton.vue';
import Toolbar from '../layout/Toolbar.vue'
import UserInvoceCard from './blocks/UserInvoceCard.vue';
import UserCardView from './blocks/UserCardView.vue';
import AddCreditCard from './components/AddCreditCard.vue';

function data() {
  return {
    invoices: null,
    sorting: '',
    cards: null,
    loadingCards: true,
    loadingInvoices: true,
    activeIndex: 'invoices',
    showAddCardDialog: false,
    pagination: {
      per_page: null,
      current: null,
      pages: null,
      total: null,
    },
    toggleOption: {
      layout: {
        color: 'black',
        backgroundColor: 'white',
        selectedColor: 'white',
        selectedBackgroundColor: 'green',
        borderColor: 'black',
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: true
      },
      size: {
        fontSize: .875,
        height: 2.125,
        padding: .4375,
        width: 19.25
      },
      config: {
        delay: 0,
        preSelected: 'invoices',
        disabled: false,
        items: [
          { name: 'Invoices', value: 'invoices', color: '#125F43', backgroundColor: '#E9F2EC' },
          { name: 'Payment methods', value: 'payment_methods', color: '#125F43', backgroundColor: '#E9F2EC' }
        ]
      }
    },
    customer: null
  }
}

async function getCustomer() {
  await this.$simplecater.get('/account').then((r) => {
    this.customer = r.data.data
  }).catch(() => {
    this.$message.error("Failed to load the user information")
  })
}

function onPaginationChange(current_page) {
  this.getInvoices(current_page);
}

function getCards() {
  this.loadingCards = true
  return this.$simplecater.get('/cards/for_account').then((r) => {
    this.cards = r.data.data
    this.loadingCards = false
  })
}

function getInvoices(current_page) {
  this.loadingInvoices = true
  let reqParams = (current_page ? { params: { page: current_page } } : { params: {} });
  if (this.sorting) {
    this.$isNumber(this.sorting) ? reqParams.params.id = this.sorting : reqParams.params.q = this.sorting
  }
  return this.$simplecater.get('/invoices', reqParams).then((r) => {
    this.invoices = _.sortBy(r.data.data, 'created_at').reverse()
    // created_at
    this.loadingInvoices = false
    this.pagination = r.data.pagination;
  })
}

function addNewCard() {
  this.showAddCardDialog = true
}

function handleNewCard({ token, cardName }) {
  let data = {
    token: token,
    name: cardName
  }
  this.$simplecater.post('/cards', data).then(() => {
    this.getCards()
    this.showAddCardDialog = false
    this.$message({
      message: 'Card added successfully',
      type: 'success'
    })
  }).catch(error => {
    this.$message({
      message: 'Failed to add card: ' + (error.response?.data?.message || 'Unknown error'),
      type: 'error'
    })
  })
}

function created() {
  this.getCards()
  this.getInvoices()
  this.getCustomer()
}

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000)
  return date.toLocaleDateString()
}

function deleteCardDialog(card, idx) {
  console.log('Delete', card, idx)
  const title = "Delete Card"
  const msg = "Are you sure you want to delete this card?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, delete it',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.deleteCard(card)
  }).catch(() => { })
}

function deleteCard(card, idx) {
  this.$simplecater.delete('/cards/' + card.id).then(() => {
    let newCards = _.map(this.cards, _.id)
    newCards.splice(idx, 1)
    this.cards = newCards
    this.$message("The card was deleted")
    this.getCards()
  }).catch(() => {
    this.$message.error('This card cannot be deleted')
  })
}

function switchTab(e) {
  this.activeIndex = e
}

function formatStatus(str) {
  if (str === 'PENDING_PAYMENT') return 'pending';
  return str.replace(/_/g, ' ').toLowerCase();
}

const watch = {
  sorting: function () {
    this.getInvoices();
  },
  '$route'() {
    console.log("Reloading data")
    this.getCards()
    this.getInvoices()
  }
}

const components = { Toolbar, DefaultButton, UserInvoceCard, UserCardView, AddCreditCard }

function mounted() {
  this.$route.query.fromRequest ? this.addNewCard() : null
}

const methods = {
  getCards, getCustomer, onPaginationChange, switchTab, getInvoices,
  addNewCard, formatUnixTimestamp, deleteCardDialog, deleteCard, formatStatus, handleNewCard
}

export default { methods, components, data, created, watch, mounted }

</script>

<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
  .search
    width: 12.5rem
  .sub-header-left-part
    display: flex
    gap: .75rem  
  .search
    max-width: 12.5rem
  .biling-header
    display: flex
    justify-content: space-between
  .page-title
    margin-bottom: 1.75rem
  .main-content
    margin-top: 1.5rem  
</style>
