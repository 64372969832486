<template lang='pug'>
div
  el-table(:data='orders', v-loading='orders == null')
    el-table-column(type='expand')
      template(slot-scope='props')
        order(:order-summary='props.row' @updateOrders='getData' :active-index='activeIndex')
    el-table-column(label='Event')
      template(slot-scope='scope')
        div
          | {{scope.row.request_name}}
        div.secondary
          | {{$formatCents(scope.row.total_cents)}}
          | Order \#{{scope.row.id}}
        div.secondary
          | People: {{scope.row.request_people}}
    el-table-column(label='Delivery')
      template(slot-scope='scope')
        div(v-show='isDueTomorrow(scope.row.delivery_date)')
          .sc-label.sc-label__danger
            i.mat-icon error
            |  DUE TOMORROW
        div(v-show='isDueToday(scope.row.delivery_date)')
          .sc-label.sc-label__danger
            i.mat-icon error
            |  DUE TODAY
        div
          i.mat-icon.secondary date_range
          |  {{$formatDate(scope.row.delivery_date)}}
        div
          i.mat-icon.secondary access_time
          |  {{ $formatTime(scope.row.delivery_time) }}

    el-table-column(label='Status')
      template(slot-scope='scope')
        status-tag(:status='scope.row.status')

    el-table-column(label='Customer/Vendor/Assignee')
      template(slot-scope='scope')
        | {{scope.row.customer_account_name}}
        br
        | {{scope.row.vendor_name}}
        br(v-if="scope.row.request_assignee")
        span(v-if="scope.row.request_assignee")
          | {{scope.row.request_assignee}}

    el-table-column(label='Created')
      template(slot-scope='scope')
        div
          i.mat-icon.secondary date_range
          |  {{$formatDate(scope.row.created_at)}}
        div
          i.mat-icon.secondary access_time
          |  {{ $formatTime(scope.row.created_at) }}

    el-table-column(label='Actions')
      template(slot-scope='scope')
        el-dropdown(class='order-list__dropdown')
          span(class='el-dropdown-link')
            | Choose action
            i(class='el-icon-arrow-down el-icon--right')
          el-dropdown-menu(slot='dropdown' class='order-list__dropdown-menu')
            el-dropdown-item(v-if='scope.row.status == "SUBMITTED" ')
              el-button(type='text', @click='markReceivedDialog(scope.row.id)')
                | Receive
            el-dropdown-item(v-if='scope.row.status == "SUBMITTED" ')
              el-button(type='text', @click='markDeclinedDialog(scope.row.id)')
                | Decline
            el-dropdown-item(v-if='scope.row.status == "ORDERED"')
              el-button(type='text', @click='scheduleDialog(scope.row)')
                | Schedule
            el-dropdown-item(v-if='scope.row.status == "RECEIVED" && scope.row.purchase_order_notes != null ')
              el-button(type='text', @click='markOrderedDialog(scope.row.id)')
                | Mark ordered
            el-dropdown-item(v-if='scope.row.status == "RECEIVED" && scope.row.purchase_order_notes == null')
              el-button(type='text', @click='generatePurchaseOrderDialog(scope.row)')
                | Generate PO
            el-dropdown-item(v-if='scope.row.purchase_order_notes != null')
              el-button(type='text', @click='$openInBlank(`/print/orders/${scope.row.id}/po`)')
                i.mat-icon open_in_new
                | View PO
            el-dropdown-item(v-if='scope.row.invoice_id != null')
              el-button(type='text', @click='$openInBlank(`/print/invoices/${scope.row.invoice_id}`)')
                i.mat-icon open_in_new
                | Invoice summary
            el-dropdown-item(v-if='scope.row.status != "SUBMITTED" && scope.row.id != null')
              el-button(v-if='true' type='text', @click='$openInBlank(`/print/orders/${scope.row.id}`)') Invoice detailed
            el-dropdown-item(v-else)
              el-button(v-if='true' type='text', @click='$openInBlank(`/print/orders/${scope.row.id}`)') Order detailed
            el-dropdown-item
              el-button(type='text', @click='onEditDialog(scope.row)')
                i.mat-icon edit
                | Edit
            el-dropdown-item(v-if='scope.row.status != "CANCELLED" && scope.row.status != "CLOSED"')
              el-button(type='text', @click='markCancelledDialog(scope.row.id)')
                | Cancel

  scheduler(:order='orderOnSchedule', :visible.sync='displayScheduler',
  v-if='displayScheduler')

  drawer( v-if="isVisibleGeneratingPO" class='order-list__drawer' dismissRoute="" @close="isVisibleGeneratingPO = false")
    .drawer-title
      i.mat-icon add_circle
      | Generate PO

    el-form(ref="form", label-width="150px", v-if='generatingPO', :rules="rules", :model="generatingPO")

      el-form-item(label='Delivery Address')
        div(v-loading='deliveryAddress === null')
          i.mat-icon place
          span(v-if='deliveryAddress') {{ deliveryAddress.address_line_1 }}

      el-form-item(label='Delivery Date')
        | {{$formatDate(generatingPO.delivery_date)}}

      el-form-item(label='Delivery Time')
        | {{$formatTime(generatingPO.delivery_time)}}

      el-form-item(label='Food Ready At', prop='food_ready_time')
        el-time-picker(
          placeholder='Select a time', v-model='generatingPO.food_ready_time',
          :picker-options="timePickerOptions",
          format="hh:mm A",
          value-format="HH:mm"
        )

      el-form-item(label='Vendor location', prop="pickup_address_id", v-loading='vendorLocations === null')
        el-select(v-model='generatingPO.pickup_address_id', placeholder='Choose one', class='full-width-select')
          el-option(v-for='x in vendorLocations', :key='x.id', :label='x.address_line_1', :value='x.id')

      el-form-item(label='Notes to vendor')
        el-input(type="textarea", :rows="4", placeholder="Notes to vendor", v-model='generatingPO.purchase_order_notes')

    .submit-dialog
      el-button(type='primary', @click='generatePurchaseOrder()')
        | Submit

      el-button(@click='isVisibleGeneratingPO = false')
        | Cancel

  el-col()
    el-pagination( 
      v-if="pagination.pages > 1"
      @current-change="onPaginationChange"
      :page-size="pagination.per_page", 
      :pager-count="5", 
      layout="prev, pager, next", 
      :total="pagination.total") 
</template>

<script>

import StatusTag from '../../common/StatusTag'
import Scheduler from './Scheduler'
import Order from './Order'
import moment from 'moment'

function data() {
  return {
    orders: null,
    displayScheduler: false,
    orderOnSchedule: null,
    isVisibleGeneratingPO: false,
    generatingPO: null,
    deliveryAdress: null,
    vendorLocations: null,
    vendor_location_id: null,
    timePickerOptions: {
      selectableRange: '00:00:00 - 23:59:00',
    },
    currentOrder: {},
    showEdit: false,
    rules: {
      food_ready_time: {
        required: true, message: 'Please input the food time',
        trigger: 'blur'
      },
      pickup_address_id: {
        required: true, message: 'Please select the address',
        trigger: 'blur'
      },
    },
    pagination: {
      per_page: null,
      current: null,
      pages: null,
      total: null,
    }
  }
}

function created() {
  this.getData();
  this.$bus.$on('order-scheduled', this.getData)
}

function markCancelledDialog(id) {
  const title = "Cancel Order"
  const msg = "The order will be cancelled. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, cancel order',
    cancelButtonText: 'Cancel',
    confirmButtonClass: 'order-list__red-btn',
    type: 'error'
  }).then(() => {
    this.markCancelled(id)
  }).catch(() => { })
}

function onEditDialog(row) {
  const target = `orders/${row.request_id}/edit`;
  this.$router.push({ path: target, query: { orderId: row.id } })
}

function onPaginationChange(current_page) {
  this.getData(current_page);
}

function markDeclinedDialog(id) {
  const title = "Decline order"
  const msg = "The order will be declined. Continue?"
  const type = "error"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, decline order',
    cancelButtonText: 'Cancel',
    confirmButtonClass: 'order-list__red-btn',
    type
  }).then(() => {
    this.markDeclined(id)
  }).catch(() => { })
}

function markReceivedDialog(id) {
  const title = "Confirm order"
  const msg = "An invoice will be created, but not processed yet. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Confirm & bill order',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.markReceived(id)
  }).catch(() => { })
}

function generatePurchaseOrderDialog(order) {
  console.log("ORDER FOR DIALOG", order)
  this.generatingPO = {
    id: order.id,
    purchase_order_notes: '',
    food_ready_time: null,
    pickup_address_id: null,
    delivery_date: order.delivery_date,
    delivery_time: order.delivery_time
  }
  this.isVisibleGeneratingPO = true
  // Fetch delivery address
  this.deliveryAddress = null;
  this.$simplecater('/addresses/' + order.request_address_id).then((r) => {
    this.deliveryAddress = r.data.data
  })
  // Fetch vendor locations
  this.vendorLocations = null
  this.$simplecater('/vendors/' + order.vendor_id + '/addresses').then((r) => {
    this.vendorLocations = r.data.data
  })
}

function markOrderedDialog(id) {
  const title = "Mark Ordered"
  const msg = "Staff notes on order"
  this.$prompt(msg, title, {
    confirmButtonText: 'Mark ordered',
    cancelButtonText: 'Cancel'
  }).then((result) => {
    const notes = result.value || ""
    this.markOrdered(id, notes)
  }).catch(() => { })
}

function generatePurchaseOrder() {
  if (this.generatingPO.pickup_address_id === null || this.generatingPO.food_ready_time === null
    || this.generatingPO.purchase_order_notes === null) {
    this.$message.error('All fields are required')
    return
  }
  const target = `/orders/${this.generatingPO.id}/purchase_order`
  const body = JSON.stringify(this.generatingPO)
  this.$simplecater.post(target, body).then(() => {
    this.$message("The purchase order was generated")
    this.generatingPO = null;
    this.isVisibleGeneratingPO = false;
    this.getData()
  }).catch(() => {
    this.$message.error("The PO generation failed")
  })
}

function scheduleDialog(order) {
  this.orderOnSchedule = order
  this.displayScheduler = true
}

function markReceived(id) {
  const target = `/orders/${id}/mark_received`
  this.$simplecater.post(target).then(() => {
    this.$message("The order has been confirmed")
    this.getData()
  }).catch(() => {
    this.$message.error("Confirmation failed")
  })
}

function markCancelled(id) {
  const target = `/orders/${id}/mark_cancelled`
  this.$simplecater.post(target).then(() => {
    this.$message("The order has been cancelled")
    this.getData()
  }).catch(() => {
    this.$message.error("Cancelling failed")
  })
}

function markDeclined(id) {
  const target = `/orders/${id}/mark_declined`
  this.$simplecater.post(target).then(() => {
    this.$message("The order has been declined")
    this.getData()
  }).catch(() => {
    this.$message.error("Decline failed")
  })
}

function markOrdered(id, notes) {
  console.log("Marking ordered", id, notes)
  const target = `/orders/${id}/mark_ordered`
  let data = {
    vendor_order_notes: notes
  }
  this.$simplecater.post(target, data).then(() => {
    this.$message("The order has been mark as ordered")
    this.getData()
    this.isVisibleGeneratingPO = false
  }).catch(() => {
    this.$message.error("Status change failed")
  })
}

function schedule() {
  this.$message("PENDING WIRING")
}

function goToInvoice(id) {
  this.$router.push(`/invoices/${id}`)
}

function goToDelivery(id) {
  this.$router.push(`/deliveries/${id}`)
}

function getData(current_page) {
  let reqParams = (current_page ? { params: { page: current_page } } : { params: {} });
  if (this.sorting) {
    this.$isNumber(this.sorting) ? reqParams.params.id = this.sorting : reqParams.params.q = this.sorting
  }

  this.$simplecater.get(this.targetUri, reqParams).then((r) => {
    this.orders = r.data.data
    this.pagination = r.data.pagination;
  }).catch(() => {
    this.$message.error('Failed to load data')
  })
}

function isDueTomorrow(date) {
  let now = new Date()
  let format = 'YYYY-MM-DD'
  let tomorrowDate = moment(new Date((new Date()).setDate(now.getDate() + 1)), format)
  return moment(date, format).isSame(tomorrowDate, 'day')
}

function isDueToday(date) {
  let now = new Date()
  let format = 'YYYY-MM-DD'
  let tomorrowDate = moment(now, format)
  return moment(date, format).isSame(tomorrowDate, 'day')
}

const watch = {
  sorting: function () {
    this.getData();
  },
  filter: function (newFilter) {
    console.log("FILTER FROM TO", this.filter, newFilter)
  },
  customer: function () {
    this.getData();
  },
  $route: function () {
    this.getData();
  }
}

const computed = {
  targetUri: function () {
    let params = `?customer_id=${this.customer}`;
    if (this.filter == 'all') {
      return `/orders${params}`
    } else {
      return `/orders/${this.filter}${params}`
    }
  }
}

const components = {
  StatusTag, Scheduler, Order
}

const methods = {
  getData, markReceived, onPaginationChange, markReceivedDialog, schedule, scheduleDialog,
  goToInvoice, goToDelivery, markOrderedDialog, markOrdered, onEditDialog,
  generatePurchaseOrderDialog, generatePurchaseOrder, isDueTomorrow, isDueToday,
  markDeclinedDialog, markDeclined, markCancelledDialog, markCancelled
}

const props = {
  filter: { required: true },
  customer: { required: false },
  sorting: { required: false },
  activeIndex: { require: false },
}

export default { methods, components, data, created, watch, props, computed }
</script>

<style lang="sass">
  .submit-dialog
    text-align: center
    margin: 1rem 0 0
  .order-list__dropdown-menu
    .el-button
      width: 100%
      display: block
  .order-list__drawer
    .drawer__content
      width: 430px
  .order-list__red-btn
    background: red !important
    border-color: red !important
    &:hover
      opacity: 0.6

</style>
