<template>
  <div class="invoce-card">
    <div class="invoce-card-left-part">
      <div class="invoce-card-number">#{{ data.id }}</div>
      <div class="invoce-card-title">
        <div class="invoce-card-title-text">
          {{ data.vendor_name }}
        </div>
        <div class="invoce-card-title-date">
          {{ $formatDate(data.delivery_date) }}
        </div>
        <div class="invoce-card-title-date" v-if="currentCustomerId !== data.customer_id">
          {{ data.customer_account_name }}
        </div>
      </div>
    </div>
    <div class="invoce-card-right-part">
      <div class="status-container right-part-item">
        <div class="invoce-card-status" :class="invoceStatus.toLowerCase()">
          {{ invoceStatus }}
        </div>
      </div>
      <div class="right-part-item">
        <div class="invoce-card-persons">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.3327 14C13.3327 13.0696 13.3327 12.6044 13.2179 12.2259C12.9593 11.3736 12.2924 10.7067 11.4401 10.4482C11.0616 10.3333 10.5964 10.3333 9.66601 10.3333H6.33269C5.40231 10.3333 4.93712 10.3333 4.55859 10.4482C3.70632 10.7067 3.03938 11.3736 2.78084 12.2259C2.66602 12.6044 2.66602 13.0696 2.66602 14M10.9993 5C10.9993 6.65685 9.6562 8 7.99935 8C6.34249 8 4.99935 6.65685 4.99935 5C4.99935 3.34315 6.34249 2 7.99935 2C9.6562 2 10.9993 3.34315 10.9993 5Z"
              stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ data.people }}
        </div>
      </div>
      <div class="right-part-item">
        <div class="invoce-card-total">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.00065 7.33341V10.0001M12.0007 6.00008V8.66675M11.334 2.66675C12.9665 2.66675 13.8494 2.91659 14.2887 3.11038C14.3472 3.13618 14.3765 3.14909 14.4609 3.22966C14.5115 3.27796 14.6039 3.41968 14.6277 3.48547C14.6673 3.59524 14.6673 3.65524 14.6673 3.77524V10.9408C14.6673 11.5467 14.6673 11.8496 14.5765 12.0053C14.4841 12.1637 14.3949 12.2373 14.2219 12.2982C14.0519 12.358 13.7086 12.2921 13.0221 12.1602C12.5416 12.0679 11.9717 12.0001 11.334 12.0001C9.33398 12.0001 7.33398 13.3334 4.66732 13.3334C3.03485 13.3334 2.1519 13.0836 1.71257 12.8898C1.65407 12.864 1.62481 12.8511 1.54038 12.7705C1.48978 12.7222 1.3974 12.5805 1.37363 12.5147C1.33398 12.4049 1.33398 12.3449 1.33398 12.2249L1.33398 5.05932C1.33398 4.45347 1.33398 4.15055 1.42484 3.99484C1.51725 3.83646 1.60638 3.76283 1.77936 3.70196C1.94941 3.64212 2.29267 3.70807 2.9792 3.83998C3.45973 3.93231 4.02964 4.00008 4.66732 4.00008C6.66732 4.00008 8.66732 2.66675 11.334 2.66675ZM9.66732 8.00008C9.66732 8.92056 8.92113 9.66675 8.00065 9.66675C7.08018 9.66675 6.33398 8.92056 6.33398 8.00008C6.33398 7.07961 7.08018 6.33341 8.00065 6.33341C8.92113 6.33341 9.66732 7.07961 9.66732 8.00008Z"
              stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ $formatCents(data.total_cents) }}
        </div>
      </div>
      <div class="right-part-item">
        <div class="invoce-card-view-invoce" @click="$emit('viewInvoceClick', data.id)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.66602 5.2C2.66602 4.0799 2.66602 3.51984 2.884 3.09202C3.07575 2.71569 3.38171 2.40973 3.75803 2.21799C4.18586 2 4.74591 2 5.86602 2H10.1327C11.2528 2 11.8128 2 12.2407 2.21799C12.617 2.40973 12.9229 2.71569 13.1147 3.09202C13.3327 3.51984 13.3327 4.0799 13.3327 5.2V14L11.4993 12.6667L9.83268 14L7.99935 12.6667L6.16602 14L4.49935 12.6667L2.66602 14V5.2Z"
              stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          View Invoice
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      require: true
    },
    currentCustomerId: {
      require: true
    }
  },

  computed: {
    invoceStatus() {
      const status = this.data.status;
      switch (status) {
        case 'PAID':
          return 'Paid'
        case 'PENDING_PAYMENT':
          return 'Open'
        case 'CANCELLED':
          return 'Canceled'
        default:
          return status
      }
    }
  }
}
</script>

<style scoped lang="sass">
.invoce-card
  background: #FFFFFF
  border-radius: 1rem
  ox-shadow: 0px .5rem 2.5rem -.25rem rgba(31, 31, 24, 0.03)
  padding: 1.5rem
  display: flex
  gap: 2.5rem
  align-items: center
  font-size: .875rem
  margin-bottom: 1rem
  &-number
    background: #F5F2D0
    border-radius: .5rem
    color: #CFA531
    padding: .375rem .25rem
    width: fit-content
  .invoce-card-left-part
    display: flex
    gap: 1.8125rem
    align-items: center
    flex: 1
    min-width: fit-content
  &-title
    font-size: 14px
    font-weight: 600
    &-date
      color: #70706B
  &-right-part
    display: flex
    justify-content: space-between
    flex: 2
  &-status
    border-radius: .5rem
    padding: .375rem .25rem
    width: fit-content
  &-persons, &-total, &-view-invoce
    display: flex
    align-items: center
    gap: .375rem
  &-view-invoce
    color: #125F43
    cursor: pointer
  .paid
    background: #F4F9F5
    color: #2BB673
  .open
    color: #DC6803
    background: #FFFAEB
  .aging
    color: #E31B54
    background: #FFF5F6
  .right-part-item
    width: 25%      
</style>