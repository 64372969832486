<style scoped lang="sass">
.request-name
  color: #000
.opportunity
  color: #222
.change-pop-up-footer
  display: flex
  gap: 2rem
  margin-top: 2rem  
.empty-container
  margin-top: 1.5rem
  img
    max-width: 20vw  
</style>
<template>
  <div>
    <!-- <el-table :data="requests" v-loading="requests == null">
    <el-table-column label="Event">
      <template slot-scope="scope">
        <div>
          <router-link :to="'/user/requests/' + scope.row.id">{{scope.row.name}}</router-link>
        </div>
        <div class="secondary"><i class="mat-icon">person</i>{{scope.row.people}}<span class="hpad">{{$formatCents(scope.row.budget_cents)}}/person</span><br/>Request #{{scope.row.id}}</div>
      </template>
</el-table-column>
<el-table-column label="Delivery">
  <template slot-scope="scope">
        <div><i class="mat-icon secondary">date_range</i> {{$formatDate(scope.row.delivery_date)}}</div>
        <div><i class="mat-icon secondary">access_time</i> {{ $formatTime(scope.row.delivery_time) }}</div>
      </template>
</el-table-column>
<el-table-column label="Status">
  <template slot-scope="scope">
        <status-tag :status="scope.row.status"></status-tag>
      </template>
</el-table-column>
<el-table-column label="Actions">
  <template slot-scope="scope">
        <ul class="actions-list">
          <li v-if="allowCancel(scope.row.status)">
            <el-button type="text" @click="markCancelledDialog(scope.row.id)">Cancel</el-button>
          </li>
          <li v-if="allowCancel(scope.row.status)">
            <el-button type="text" @click="onEditDialog(scope.row.id)">Edit</el-button>
          </li>
        </ul>
      </template>
</el-table-column>
</el-table>
<el-pagination v-if="pagination.pages &gt; 1" @current-change="onPaginationChange" :page-size="pagination.per_page"
  :pager-count="pagination.pages" layout="prev, pager, next" :total="pagination.total"> </el-pagination> -->
    <div v-for="request in filtredRequest" :key="request.id">
      <request-card 
        :request="request" 
        :currentCustomerId="customer.id"
        @editClick="onEditDialog($event)" 
        :closed="filter === 'closed'"
        @cancelClick="markRequestCancelled(request.id)" />
    </div>
    
    <pop-up :showPopUp="showCancelPopUp" :title="'Cancel request'" @closePopUp="showCancelPopUp = false">
      <div class="change-pop-text">
        The request will be cancelled. Continue ?
      </div>
      <div class="change-pop-up-footer">
        <base-button @onClick="showCancelPopUp = false">Cancel</base-button>
        <base-button :confirmBtn="true" @onClick="markCancelled(currentRequest)">Yes, cancel request</base-button>
      </div>
    </pop-up>

    <div v-if="filtredRequest && filtredRequest.length === 0" class="empty-container">
      <empty-block :imagePath="'Ordering'" title="There are no open requests available" />
    </div>

  </div>
</template>

<script>
import StatusTag from '../../common/StatusTag.vue'
import EmptyBlock from './EmptyBlock.vue'
import RequestCard from './RequestCard.vue'
import PopUp from '@/common/PopUp.vue'
import BaseButton from './BaseButton.vue'

function data() {
  return {
    customer: null,
    requests: null,
    pagination: {
      per_page: null,
      current: null,
      pages: null,
      total: null,
    },
    showCancelPopUp: false,
    currentRequest: ''
  }
}

async function getCustomer() {
  await this.$simplecater.get('/account').then((r) => {
    this.customer = r.data.data
  }).catch(() => {
    this.$message.error("Failed to load the user information")
  })
}

function created() {
  this.$bus.$on('request-created', this.getData)
  this.getData()
  this.getCustomer()
}

function onPaginationChange(current_page) {
  this.getData(current_page);
}

function allowCancel(status) {
  return (status != "CANCELLED"
    && status != "ESTIMATE_ORDERED"
    && status != "CLOSED")
}

function onEditDialog(id) {
  const target = `requests/${id}/edit`;
  this.$router.push(target)
}

function getData(current_page) {
  let reqParams = (current_page ? { params: { page: current_page } } : { params: {} });
  if (this.sorting) {
    this.$isNumber(this.sorting) ? reqParams.params.id = this.sorting : reqParams.params.q = this.sorting
  }
  this.$simplecater.get(this.targetUri, reqParams).then((r) => {
    this.requests = r.data.data;
    this.pagination = r.data.pagination;
  }).catch(() => {
    this.$message.error('Failed to load data')
  })
}

function markRequestCancelled(id) {
  this.currentRequest = id
  this.showCancelPopUp = true
}

function markCancelledDialog(id) {
  const title = "Cancel request"
  const msg = "The request will be cancelled. Continue?"
  this.$confirm(msg, title, {
    confirmButtonText: 'Yes, cancel request',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.markCancelled(id)
  }).catch(() => { })
}

function markCancelled(id) {
  const target = `/requests/${id}/mark_cancelled`
  this.$simplecater.post(target).then(() => {
    this.$message("The request has been cancelled")
    this.getData()
    this.showCancelPopUp = false
  }).catch(() => {
    this.$message.error("Cancelling failed")
  })
}

const computed = {
  targetUri: function () {
    if (this.filter == 'all') {
      return '/requests'
    } else {
      return `/requests/${this.filter}`
    }
  },
  filtredRequest: function () {
    if (this.requests) {
      if (this.curentFilterStatus !== 'ALL_STATUSES') {
        const filtredRequests = this.requests.filter(request => {
          return request.status === this.curentFilterStatus
        })
        return filtredRequests
      } else {
        return this.requests
      }
    } else return null

  }
}

const watch = {
  sorting: function () {
    this.getData();
  },
  filter: function (newFilter) {
    console.log("FILTER FROM TO", this.filter, newFilter)
  }
}

const components = {
  StatusTag,
  RequestCard,
  PopUp, BaseButton,
  EmptyBlock
}

const methods = {
  getCustomer, getData, onPaginationChange,
  markCancelledDialog, markCancelled, allowCancel,
  onEditDialog, markRequestCancelled
}

const props = {
  filter: { required: true },
  sorting: { required: false },
  curentFilterStatus: { required: true }
}

export default { methods, components, data, created, watch, props, computed }
</script>
